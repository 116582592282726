import React from 'react'
import { ClientWrapper } from './Styled'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, MainHeading, SubHeading, } from '../../Styled/Elements'
import { useTranslation } from 'react-i18next'
import { ClientData } from '../../data/Data'
import { useInView } from 'react-intersection-observer'
import { Animate, AnimateGroup } from 'react-simple-animate'

const Client = () => {
    const { t, i18n } = useTranslation();
    const [ref, inView] = useInView({
        triggerOnce: true,
    });
    const isRTL = i18n.dir() === 'rtl';
    const start = isRTL
        ? { opacity: 0, transform: "translateX(15px)" }
        : { opacity: 0, transform: "translateX(-15px)" };
    const end = { opacity: 1, transform: "translateX(0)" };

    return (
        <ClientWrapper id='client'>
            <Container>
                <Row className=''>
                    <Box className='heading-content'>
                        <SubHeading className='sub-heading before_after' color='var(--themeColor)'> {t('Our_Clients')} </SubHeading>
                        <MainHeading className='themeColor main-heading'> {t('Our_Clients_content')} </MainHeading>
                    </Box>
                </Row>
                <Row className='client-container'>
                    <Col>
                        <Box ref={ref} className='client-wrap'>
                            {
                                ClientData.map((list, key) => (
                                    <AnimateGroup play={inView}>
                                        <Animate
                                            delay={list.delay}
                                            sequenceIndex={list.sequenceIndex}
                                            start={start}
                                            end={end}
                                        >
                                            <Box key={key} className='client-content'>
                                                {list.logo}
                                            </Box>
                                        </Animate>
                                    </AnimateGroup>
                                ))
                            }

                        </Box>
                    </Col>
                </Row>
            </Container>

        </ClientWrapper>
    )
}

export default Client