import React from 'react'
import { FooterWrapper } from './Styled'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, HeadingStyled, SpanStyled } from '../Styled/Elements'
import {  Ffacebook, Finstagram, Flogo,  Fsnapchat, Ftwitter } from '../Styled/AllImages'
import { NavDataConfig } from '../data/Data'
import { Link } from 'react-scroll'
import { useTranslation } from 'react-i18next'

const Footer = () => {
    const { t } = useTranslation();
    // const isRTL = i18n.dir() === 'rtl';
    const FowlWeb = () => {
        window.open('https://websites.fowl.sa/', '_blank');
    };
    const date_year = new Date();
    const socialIcon = [
        {
            img: <Ffacebook />,
            // link: 'https://twitter.com/deyamlandscape',
        },

        {
            img: <Ftwitter />,
            // link: 'https://www.tiktok.com/@deyamlandscape',
        },
        {
            img: <Finstagram />,
            // link: 'https://www.linkedin.com/company/deyam-landscape-real-estate',
        },
        {
            img: <Fsnapchat />,
            // link: 'https://www.linkedin.com/company/deyam-landscape-real-estate',
        },
    ];
    return (
        <FooterWrapper className='container-fluid'>
            <Container>
                <Row className='footer-row align-items-center'>
                    <Col lg={2} md={12} sm={12} >
                        <Box className='f-logo-wrap'>
                            <Box className='footer-logo'>
                                <Flogo />
                            </Box>
                        </Box>
                    </Col>
                    <Col lg={8} md={12} sm={12} >
                        <Box className='d-flex flex-column justify-content-center align-items-center'>
                            <Box className='footer-list'>
                                {NavDataConfig.map((item, key) => (
                                    <Box key={key} className='footer-nav-items d-flex gap-2'>
                                        <Link to={item.to} spy={true} smooth={true} offset={-60} duration={500}>{t(item.label)}</Link>
                                    </Box>
                                ))}
                            </Box>
                            <Box className='text-center'>
                                <SpanStyled className='footer-op' size='13px' color='var(--white)' transform='capitalize'>{t('AllRightReserved')} &copy; {date_year.getFullYear()} </SpanStyled>
                                <SpanStyled className='footer-op' size='13px' color='var(--white)' transform='capitalize'>
                                    <SpanStyled className='footer-op fowl' size='14px' family="var(--bold)"> {t('Abraj_alamal')}</SpanStyled>
                                </SpanStyled>
                                <HeadingStyled className='footer-op' size='13px' color='var(--white)' transform='capitalize'>{t('Developed_by')}
                                    <SpanStyled className='fowl footer-op' color='var(--white)' size='14px' family="var(--bold)" onClick={FowlWeb}> {t('Fowl')}</SpanStyled>
                                </HeadingStyled>
                            </Box>
                        </Box>
                    </Col>
                    <Col lg={2} md={12} sm={12} >
                        <Box className='d-flex align-items-center gap-3 footerSocial'>
                            {
                                socialIcon.map((list, key) => (
                                    <Box key={key} className='contact-links'>
                                        <a href={list.link} target="_blank" rel="noopener noreferrer">
                                            {list.img}
                                        </a>
                                    </Box>
                                ))
                            }
                        </Box>
                    </Col>
                </Row>
            </Container>
        </FooterWrapper>
    )
}

export default Footer