import styled from "styled-components";
import { UploadImg } from "../../Styled/AllImages";

export const ContactWrapper = styled.section`
.containerContact{
    padding-top:80px;
}
position:relative;
.bg-setting{
 border-radius:12px;
 position:relative;
}
.contact-info{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.btn-text{
    background: var(--gradient);
    padding: 8px 12px;
    border-radius: 8px;
    & div{
        color:var(--white);
    }
    & svg{
        [fill]{
            fill:var(--white);
        }
    }
}

.contact-Us{
    border-radius:10px;
}
.contact-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    position: relative;
    padding:20px 20px;
}
.contact-bm-logo{
    width:100%;
    position: relative;
    bottom: 5px;
}
    
`
export const ContactFormWrapper = styled.div`
.field-box{
    display: flex;
    flex-direction: column;
    gap:8px;
    margin:20px 0;
}
.input-field,
.file-attached{
    padding: 10px 15px;
    border-radius: 10px;
    border: none;
    outline: none;
    color:var(--text);
    background: color(srgb 1 1 1 / 0.10);

}
// .file-attached{
//     padding: 10px 15px;
//     border-radius: 10px;
//     border: none;
//     outline: none;
//     color:var(--textPalceholder);
//     background: var(--white);
//     box-shadow: 0px 0px 9px 0px rgb(196 196 196 / 36%);
//     -webkit-box-shadow: 0px 0px 9px 0px rgb(196 196 196 / 36%);
//     -moz-box-shadow: 0px 0px 9px 0px rgb(196 196 196 / 36%);

// }

.input-field::placeholder{
    color:var(--textPalceholder);
}
.error-message{
    font-family:var(--arRegular);
    border:1px solid #ff00006b;
    color:white;
    padding:6px 8px;
    border-radius:8px;
    background-color: #ff00006b;
}
.loading-btn{
    position: absolute;
    left: 0;
    right: 0;
    width: 50%;
    height: 20%;
    z-index: 10;
    background: #0b0b0b6b;
    text-align: center;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color:var(--white);
    font-family:var(--regular);

}
.contact-social-links{
    position: relative;
    left: 15px;
}
.contact-btn svg{
    [fill]{
        fill:var(--white);
    }
    [Stroke]{
        Stroke:var(--white);
    }
}


// input[type="file"] {
//     position: relative;
//     border:1px dashed var(--text);
//   }
  
//   input[type="file"]::file-selector-button {
//     width: 180px;
//     color: transparent;
    
//   }
  
  /* Faked label styles and icon */
  input[type="file"]::before {
    position: absolute;
    pointer-events: none;
    top: 19px;
    left: 16px;
    height: 20px;
    width: 20px;
    content: "";
    background-image: url(${UploadImg});
    background-repeat: no-repeat;
    
  }
  
  input[type="file"]::after {
    position: absolute;
    pointer-events: none;
    top: 19px;
    left: 40px;
    color: var(--textPalceholder);
    content: "Upload Attechment";
  }
 
  /* ------- From Step 1 ------- */
  
  /* file upload button */
  input[type="file"]::file-selector-button {
    // border-radius: 4px;
    padding: 0 16px;
    height: 40px;
    cursor: pointer;
    background-color: white;
    border: none;
    // box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    margin-right: 16px;
    transition: background-color 200ms;
  }
  
//   /* file upload button hover state */
//   input[type="file"]::file-selector-button:hover {
//     background-color: #f3f4f6;
//   }
  
//   /* file upload button active state */
//   input[type="file"]::file-selector-button:active {
//     background-color: #e5e7eb;
//   }
  
  /* ------------------------ */
  


`