import React from 'react'

const BannerParticals = () => {
    const circleContainers = Array.from({ length: 100 }, (_, index) => (
        <div key={index} className="circle-container">
            <div className="circle"></div>
        </div>
    ));
    return (
        <div className="container particals">
            {circleContainers}
        </div>
    )
}

export default BannerParticals