import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Box } from '../Styled/Elements';

const CursorPointer = () => {
    const { i18n } = useTranslation();
    const [position, setPosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const addEventListeners = () => {
            document.addEventListener("mousemove", onMouseMove);
        };

        const removeEventListeners = () => {
            document.removeEventListener("mousemove", onMouseMove);
        };

        const onMouseMove = (e) => {
            setPosition({ x: e.pageX, y: e.pageY });
        };

        addEventListeners();
        return () => removeEventListeners();
    }, []);

    const isRTL = i18n.dir() === 'rtl';

    return (
        <>
            <CursorPointerWrapper className='cursor-container'>


                {isRTL ?
                    (
                        <Box>
                            <div
                                style={{ transform: `translate(${position.x}px, ${position.y}px)` }}
                                className="rtl-cursor"
                            ></div>
                            <div
                                style={{ transform: `translate(${position.x}px, ${position.y}px)` }}
                                className="rtl-cursor-follower"
                            ></div>
                        </Box>
                    ) :
                    (
                        <Box>
                            <Box
                                style={{ transform: `translate(${position.x}px, ${position.y}px)` }}
                                className="cursor"
                            ></Box>
                            <Box
                                style={{ transform: `translate(${position.x}px, ${position.y}px)` }}
                                className="cursor-follower"
                            ></Box>
                        </Box>
                    )}

            </CursorPointerWrapper >

        </>

    );
}

export default CursorPointer

const CursorPointerWrapper = styled.div`

.cursor {
    width: 6px;
    height: 6px;
    background: var(--darkColor);
    border-radius: 10px;
    position: absolute;
    z-index: 11;
    transition-duration: 1.2s;
    transition-timing-function: cubic-bezier(0.07, 1.18, 1, 1);
}

.cursor-follower {
    position: absolute;
    width: 15px;
    height: 15px;
    border: 2px solid var(--darkColor);
    border-radius: 100px;
    transition-duration: 0.1s;
    transition-timing-function: cubic-bezier(0.07, 1.18, 1, 1);
    z-index: 10;
}

.rtl-cursor {
    right: auto;
    left: 0;

    width: 6px;
    height: 6px;
    background: var(--darkColor);
    border-radius: 10px;
    position: absolute;
    z-index: 20;
    transition-duration: 1.2s;
    transition-timing-function: cubic-bezier(0.07, 1.18, 1, 1);
}

.rtl-cursor-follower {
    right: auto;
    left: 0;

    position: absolute;
    width: 15px;
    height: 15px;
    border: 2px solid var(--darkColor);
    border-radius: 100px;
    transition-duration: 0.1s;
    transition-timing-function: cubic-bezier(0.07, 1.18, 1, 1);
    z-index: 10;
}

`