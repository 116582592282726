import React from 'react'
import { AchievementWrapper } from './Styled'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, MainHeading, SubHeading, TextStyled } from '../../Styled/Elements'
import { useTranslation } from 'react-i18next'
import { AchieveBg } from '../../Styled/AllImages'
import { achieveData } from '../../data/Data'
import { useInView } from 'react-intersection-observer'
import { Animate, AnimateGroup } from 'react-simple-animate'

const Achievement = () => {
    const { t } = useTranslation();
    const [ref, inView] = useInView({
        triggerOnce: true,
    });
    const start = { opacity: 0, transform: "translateY(-15px)" };
    const end = { opacity: 1, transform: "translateY(0)" };
    return (
        <AchievementWrapper className='common-space' style={{ backgroundImage: `url(${AchieveBg})` }} >
            <Container>
                <Row>
                    <Box className='heading-content'>
                        <SubHeading className='sub-heading before_after_white' color='var(--white)'>{t('Pathways_Success')}</SubHeading>
                        <MainHeading className='themeColor main-heading'> {t("Our_Achievements")} </MainHeading>
                    </Box>
                </Row>
                <Row ref={ref}>
                    {
                        achieveData.map((data, index) => (

                            <Col key={index} lg={4} md={12}>
                                <AnimateGroup play={inView}>
                                    <Animate

                                        delay={data.delay}
                                        sequenceIndex={data.sequenceIndex}
                                        start={start}
                                        end={end}
                                    >
                                        <Box className='achieve-box'>
                                            <MainHeading dir="ltr" className='main-heading themeColor'> {t(data.heading)} + </MainHeading>
                                            <TextStyled color="var(--white)">{t(data.text)}</TextStyled>
                                        </Box>
                                    </Animate>
                                </AnimateGroup>
                            </Col>
                        ))
                    }

                </Row>
            </Container>

        </AchievementWrapper>
    )
}

export default Achievement