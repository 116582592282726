import styled from "styled-components";

export const ClientWrapper = styled.section`
.client-wrap{
    background-color: var(--white);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 35px;
    padding: 30px;
    border:1px solid #D8DBEF;
}
.client-content{
    display: flex;
    align-items: center;
    justify-content: center;
}
.Ceo{
    // padding-top:50px;
    display:flex;
    align-items:center;
}
.client-container{
    position: relative;
    top: 70px;
    z-index: 1;
}

`
