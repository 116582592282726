
import Footer from "../components/footer/Footer"
import Header from "../components/header/Header"
import About from "../components/sections/about/About"
import Banner from "../components/sections/banner/Banner"
import Choose from "../components/sections/choose/Choose"
import Client from "../components/sections/client/Client"
import Contact from "../components/sections/contact/Contact"
import GalleryData from "../components/sections/gallery/GalleryData"
import Portfolio from "../components/sections/gallery/Portfolio"
import Service from "../components/sections/services/Service"

const Index = () => {
    return (
        <>
            <Header />
            <Banner />
            <About />
            <Service />
            <Choose />
            {/* <GalleryData /> */}
            <Portfolio />
            <Client />
            <Contact />
            <Footer />
        </>
    )
}

export default Index