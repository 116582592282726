import { Link } from 'react-scroll'
import React from 'react'
import { BannerWrapper } from './Styled'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Box, MainHeading, SpanStyled, TextStyled } from '../../Styled/Elements'
import { ArrowRight, Facebook, Instagram, Snapchat, Twitter } from '../../Styled/AllImages'
import { ContactButton } from '../../Styled/CustomElements'
import BannerParticals from './BannerParticals'

const Banner = () => {
    const { t } = useTranslation();
    const WhatsApp = () => {
        const phoneNumber = '+966551199935';
        const message = 'Hello, Abraaj  ';
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };

    const socialIcon = [
        {
            img: <Facebook />,
            // link: 'https://twitter.com/deyamlandscape',
        },
        {
            img: <Twitter />,
            // link: 'https://twitter.com/deyamlandscape',
        },

        {
            img: <Instagram />,
            // link: 'https://www.tiktok.com/@deyamlandscape',
        },
        {
            img: <Snapchat
            />,
            // link: 'https://www.linkedin.com/company/deyam-landscape-real-estate',
        },

    ];
    return (
        <>
            <BannerWrapper className='' id='home'>
                <Container>
                    <Row>
                        <Col lg={5} md={12} className='position-relative'>
                            <Box className='banner-content-wrapper'>
                                <MainHeading className='silverColor' size='48px'>{t('banner_heading')} </MainHeading>
                                <TextStyled className='silverColor'>{t('banner_text')}</TextStyled>
                                <SpanStyled className='d-flex align-items-center gap-2'>
                                    <ContactButton icon={ArrowRight} label={t('Get_Started')} onClick={WhatsApp} />
                                </SpanStyled>
                            </Box>
                        </Col>
                    </Row>
                    <Box className='social-link'>
                        {
                            socialIcon.map((list, key) => (
                                <Box key={key}>
                                    <a href={list.link} target="_blank" rel="noopener noreferrer">
                                        {list.img}
                                    </a>
                                </Box>
                            ))
                        }
                    </Box>
                </Container>
                <BannerParticals />
            </BannerWrapper >
        </>
    )
}

export default Banner
