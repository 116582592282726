import styled from "styled-components";

export const FooterWrapper = styled.footer`
padding:12px 0;
background: var(--themeColor);
background-repeat: no-repeat;
    // background-position: right 5px;
.footer-list{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    & a{
        color: var(--white);
        font-family: var(--semibold);
        &:hover{
            // color: var(--text);
        }
    }
    
}
.footer-list a{
    color:var(--white);
    padding-bottom: 8px;
    position: relative;
    font-size:16px;
    font-family: var(--regular);
    font-weight: 400;
    &:hover, &.active, &:focus{
        color:var(--white);
        font-family: var(--semibold);
        font-weight: 400;
        &::before, &.active::before{
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            width: 15px;
            height: 3px;
            background: var(--lightgreen);
        }
    }
}
.footer-revised{
    position:relative;
    &::before{
        content:' ';
        position:absolute;
        top:0;
        right:0;
        left:-20px;
        margin:auto;
        width:101%;
        height:1px;
        background-color:#8C919D;
    }
    padding:25px 25px 25px 25px;
}
.fowl{
    cursor: pointer;
}
.footer-logo{
    width:80px;
}
.f-logo-wrap{
    display:flex;
    align-items:start;
    justify-content:start;
}

`