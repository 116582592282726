import React from 'react'
import { PortfolioWrapper } from './Styled'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, LazyImage, MainHeading, SubHeading, TextStyled } from '../../Styled/Elements'
import { useTranslation } from 'react-i18next'
import { PortfolioImg } from '../../Styled/AllImages'

const Portfolio = () => {
    const { t } = useTranslation();
    return (
        <PortfolioWrapper className='common-space'>
            <Container>
                <Row className='common-space'>
                    <Box className='heading-content'>
                        <SubHeading className='sub-heading before_after' color='var(--darkColor)'> {t('Our_Portfolio')} </SubHeading>
                        <MainHeading className='themeColor main-heading portfolio-heading'> {t('Our_Portfolio_heading')} </MainHeading>
                        {/* <TextStyled className='text-center'> {t('Our_Portfolio_content')} </TextStyled> */}
                    </Box>
                </Row>
                <Row className='common-space align-items-center'>
                    <Col lg={4} md={12} className='heading-content'>
                        <Box className='img-container'>
                            <LazyImage src={PortfolioImg} />
                        </Box>
                    </Col>
                    <Col lg={8} md={12} >
                        <Box className='heading-content'>
                            <TextStyled> {t('Our_Portfolio_content')} </TextStyled>
                        </Box>
                    </Col>
                </Row>
            </Container>
        </PortfolioWrapper>
    )
}

export default Portfolio