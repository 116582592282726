import React, { useState } from 'react'
import { ServiceWrapper } from './Styled'
import { Col, Container, Row, } from 'react-bootstrap'
import { Box, HeadingStyled, MainHeading, SubHeading } from '../../Styled/Elements'
import { useTranslation } from 'react-i18next'
import { maintenanceData, productTabs } from '../../data/Data'

import { useInView } from 'react-intersection-observer'
import { Animate } from 'react-simple-animate'
import { Mainten1, Operation1, Operation2, Operation3, Operation4, Operation5 } from '../../Styled/AllImages'

const Service = () => {
    const { t, i18n } = useTranslation();
    const [serviceType, setServiceType] = useState('Maintenance');
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    const startUptoDown = { opacity: 0, transform: 'translateY(-90px)' };
    const endUptoDown = { opacity: 1, transform: 'translateY(0)' };
    const startDowntoUp = { opacity: 0, transform: 'translateY(100px)' };
    const endDowntoUp = { opacity: 1, transform: 'translateY(0)' };

    const startOp = { opacity: 0, transform: 'translateY(-200px)' };
    const endOp = { opacity: 1, transform: 'translateY(0)' };
    const startOp2 = { opacity: 0, transform: 'translateY(200px)' };
    const endOp2 = { opacity: 1, transform: 'translateY(0)' };
    const startFade = { opacity: 0, transform: ' scale(0)' };
    const endFade = { opacity: 1, transform: ' scale(1)' };

    return (
        <>
            <ServiceWrapper id='service' className=' common-space service-bg'>
                <Container>
                    <Row ref={ref} className='align-items-center justify-content-center'>
                        <Box className='heading-content'>
                            <SubHeading className='sub-heading before_after' color='var(--darkColor)'> {t('service_heading')} </SubHeading>
                            <MainHeading className='themeColor main-heading'> {t('EMPOWER_YOUR_BUSINESS')} </MainHeading>
                        </Box>
                    </Row>
                    <Row className='align-items-center justify-content-center'>
                        <Box className='product-wrapper'>
                            <Box className='product-wrapper-tabs'>
                                <SubHeading
                                    size='18px'
                                    onClick={() => setServiceType("Maintenance")}
                                    className={`${serviceType === "Maintenance" ? "activeTab" : ""} }`}
                                >
                                    {t('Maintenance')}
                                </SubHeading>
                                <SubHeading
                                    size='18px'
                                    onClick={() => setServiceType("Operations")}
                                    className={`${serviceType === "Operations" ? "activeTab" : ""} }`}
                                >
                                    {t('Operations')}
                                </SubHeading>
                            </Box>
                        </Box>
                    </Row>

                    {serviceType === "Maintenance" && (
                        <Row className='position-relative common-space'>
                            <Box>
                                <Row className='tab-active-row'>
                                    {
                                        maintenanceData.slice(0, 4).map((item, Index) => (
                                            <Col lg={3} md={6} sm={12}>
                                                <Animate
                                                    play={inView}
                                                    delay={0.7}
                                                    duration={1}
                                                    start={startUptoDown}
                                                    end={endUptoDown}
                                                >
                                                    <Box key={Index} className='activeTab-box'>

                                                        <Box className='active-tab-content service-bg' style={{ background: `url(${item.img})` }}>
                                                            <HeadingStyled className='service-text' size='16px' color='var(--white)' transform='math-auto'> {t(item.text)}</HeadingStyled>
                                                        </Box>
                                                    </Box>
                                                </Animate>
                                            </Col>
                                        ))
                                    }
                                    {maintenanceData.slice(4, 5).map((item, Index) => (
                                        <Col lg={3} md={6} sm={12}>
                                            <Animate
                                                play={inView}
                                                delay={0.7}
                                                duration={1}
                                                start={startDowntoUp}
                                                end={endDowntoUp}
                                            >
                                                <Box key={Index} className='activeTab-box'>
                                                    <Box className='active-tab-content service-bg' style={{ background: `url(${item.img})` }}>
                                                        <HeadingStyled className='service-text' size='16px' color='var(--white)' transform='math-auto'> {t(item.text)}</HeadingStyled>
                                                    </Box>
                                                </Box>
                                            </Animate>
                                        </Col>
                                    ))}
                                    {maintenanceData.slice(5, 6).map((item, Index) => (
                                        <Col lg={6} md={6} sm={12}>
                                            <Animate
                                                play={inView}
                                                delay={0.7}
                                                duration={1}
                                                start={startDowntoUp}
                                                end={endDowntoUp}
                                            >
                                                <Box key={Index} className='activeTab-box'>
                                                    <Box className='active-tab-content service-bg' style={{ background: `url(${item.img})` }}>
                                                        <HeadingStyled className='service-text' size='16px' color='var(--white)' transform='math-auto'> {t(item.text)}</HeadingStyled>
                                                    </Box>
                                                </Box>
                                            </Animate>
                                        </Col>
                                    ))}
                                    {maintenanceData.slice(6, 7).map((item, Index) => (
                                        <Col lg={3} md={6} sm={12}>
                                            <Animate
                                                play={inView}
                                                delay={0.7}
                                                duration={1}
                                                start={startDowntoUp}
                                                end={endDowntoUp}
                                            >
                                                <Box key={Index} className='activeTab-box'>
                                                    <Box className='active-tab-content service-bg' style={{ background: `url(${item.img})` }}>
                                                        <HeadingStyled className='service-text' size='16px' color='var(--white)' transform='math-auto'> {t(item.text)}</HeadingStyled>
                                                    </Box>
                                                </Box>
                                            </Animate>
                                        </Col>
                                    ))}
                                </Row>
                            </Box>
                        </Row>
                    )}


                    {serviceType === "Operations" && (
                        <Box className='Operations-container common-space'>

                            <Box className='Operations-box item-1'>
                                <Animate
                                    play={inView}
                                    delay={0.7}
                                    duration={1}
                                    start={startOp}
                                    end={endOp}
                                >
                                    <Box className='Operations-active-tab service-bg' style={{ background: `url(${Operation1})` }}>
                                        <HeadingStyled className='service-text' size='16px' color='var(--white)' transform='math-auto'> {t('Hospitality_Cleaning')} </HeadingStyled>
                                    </Box>
                                </Animate>
                            </Box>
                            <Box className='Operations-box  item-2'>
                                <Animate
                                    play={inView}
                                    delay={0.7}
                                    duration={1}
                                    start={startFade}
                                    end={endFade}
                                >
                                    <Box className='Operations-active-tab service-bg' style={{ background: `url(${Operation2})` }} >
                                        <HeadingStyled className='service-text' size='16px' color='var(--white)' transform='math-auto'> {t('Asphalt_Road')} </HeadingStyled>
                                    </Box>
                                </Animate>
                            </Box>
                            <Box className='Operations-box item-3'>
                                <Animate
                                    play={inView}
                                    delay={0.7}
                                    duration={1}
                                    start={startFade}
                                    end={endFade}
                                >
                                    <Box className='Operations-active-tab service-bg' style={{ background: `url(${Operation3})` }}>
                                        <HeadingStyled className='service-text' size='16px' color='var(--white)' transform='math-auto'> {t('Electrical_Workers')} </HeadingStyled>
                                    </Box>
                                </Animate>
                            </Box>
                            <Box className='Operations-box  item-4'>
                                <Animate
                                    play={inView}
                                    delay={0.7}
                                    duration={1}
                                    start={startOp2}
                                    end={endOp2}
                                >
                                    <Box className='Operations-active-tab service-bg' style={{ background: `url(${Operation4})` }}>
                                        <HeadingStyled className='service-text' size='16px' color='var(--white)' transform='math-auto'>{t('Site_Preparation') }</HeadingStyled>
                                    </Box>
                                </Animate>
                            </Box>
                            <Box className='Operations-box  item-5'>
                                <Animate
                                    play={inView}
                                    delay={0.7}
                                    duration={1}
                                    start={startFade}
                                    end={endFade}
                                >
                                    <Box className='Operations-active-tab service-bg' style={{ background: `url(${Operation5})` }}>
                                        <HeadingStyled className='service-text' size='16px' color='var(--white)' transform='math-auto'>{t('Supply_Works')}</HeadingStyled>
                                    </Box>
                                </Animate>
                            </Box>
                        </Box>
                    )}



                </Container>
            </ServiceWrapper >
        </>
    )
}

export default Service