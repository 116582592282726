import styled from "styled-components";

export const ChooseWapper = styled.section`
.comparing{
    margin-top:20px;
    margin-bottom:120px;
}
.comparing .Box-shadow{
    border:1px solid #D8DBEF;
    border-radius:20px;
}
.Choose-digital{
    position: relative;
    background-color: var(--gray);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 40px 20px 0px 30px;
    border-right:1px solid #D8DBEF;
    height: 100%;
   
}
.project-other {
    position: relative;
    padding: 40px 18px 0px 25px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 100%;
    & .project-bg{
        position: absolute;
        bottom: 0px;
        right: 40px;
        width:190px;
    }
}
.project-wapper{
    margin: 20px 0;
}
.project-content{
    display: flex;
    align-items: flex-start;
    gap: 12px;
    &>div{
        width:100%;
    }
}
.compaire{
    display: flex;
    align-items: flex-start;
    gap: 12px;
    &>div{
        width:100%;
    }
}
`
export const WorkerWraper = styled.div`
    position:relative;
    & >span{
        position:relative;
        width:100%;
    }
    
    .worker-logo{
        position: absolute;
        bottom:-5px;
        left:0;
        right: 100px;
        margin:auto;
    }
    .workers-man{
        position:relative;
        & >span{
            position: absolute;
            bottom: -226px;
            right: 0px;
            // width: 610px;
        }
    }
    .whpPf{
        display: flex;
        gap: 10px;
        align-items: center;
        .title-container{
            a{
                white-space: nowrap;
            }
        }
    }
    .btn-text{
        background: var(--gradient);
        padding: 8px 12px;
        border-radius: 8px;
        cursor: pointer;
        & div{
            color:var(--white);
            white-space: nowrap;
        }
        & svg{
            [fill]{
                fill:var(--white);
            }
        }
        & > div{
            margin-left: 0 !important;
        }
    }
    
`