import { Link } from 'react-scroll'
import { Box } from '../Styled/Elements'
import { HeaderContainer } from './Styled'
import { NavDataConfig } from '../data/Data'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { BsJustify, BsX } from "react-icons/bs"
import { Container, Row } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { Phone, Logo, ArrowRight } from '../Styled/AllImages'
import { ButtonWithIcon } from '../Styled/CustomElements'
import ChangeButton from '../../Languages/ChangeButton'

const Header = () => {
    const navigate = useNavigate()
    const WhatsApp = () => {
        const phoneNumber = '+966551199935';
        const message = 'Hello, Abraaj  ';
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };
    // const WhatsApp2 = () => {
    //     const phoneNumber = '+966582969075';
    //     const message = 'Hello, Abraaj  ';
    //     const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    //     window.open(whatsappURL, '_blank');
    // };
    // const phoneCall = () => {
    //     window.location.href = `tel:+923030585881`;
    // };
    const { t } = useTranslation();
    const [isSticky, setIsSticky] = useState(false);
    const [toggle, setToggle] = useState(false);

    const handleToggle = () => {
        setToggle(!toggle);
        const element = document.querySelector(".nav-bar-list");
        element.classList.toggle("showToggle");
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 1) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (

        <HeaderContainer className={`border-bottom-line ${isSticky ? 'sticky' : ''} container-fluid`}>
            <Container>
                <Row>
                    <Box className='nav-bar'>
                        <Box className='logo' onClick={() => navigate('/')}>
                            <Logo />
                        </Box>
                        <Box className='nav-bar-list'>
                            {NavDataConfig.map((item, key) => (
                                <Box key={key}>
                                    <Link to={item.to} spy={true} smooth={true} offset={-100} duration={500} activeClass="active" onClick={handleToggle}>{t(item.label)}</Link>
                                </Box>
                            ))}
                        </Box>
                        <Box className='d-flex align-items-center'>
                            <ButtonWithIcon clName='header-btn gradient-bg d-flex gap-2' icon={Phone} label='+966596774655' onClick={WhatsApp} />
                            {/* <ButtonWithIcon clName='header-btn gradient-bg d-flex gap-2' icon={Phone} label='+966582969075' onClick={WhatsApp2} /> */}
                            <span className="toggleBtn" onClick={handleToggle}>
                                {toggle ? <BsX size={30} /> : <BsJustify size={30} />}
                            </span>
                        </Box>
                    </Box>
                </Row>
            </Container>
            <ChangeButton />
        </HeaderContainer>

    )
}


export default Header
