import React from 'react'
import { AboutWrapper } from './Styled'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, MainHeading, SubHeading, TextStyled } from '../../Styled/Elements'
import { About1, About2, AboutBg, UnderLine } from '../../Styled/AllImages'
import { useInView } from 'react-intersection-observer'
import { Animate } from 'react-simple-animate'
import Achievement from './Achievement'


const About = () => {
    const { t } = useTranslation();
    const [ref, inView] = useInView({
        triggerOnce: true,
    });
    const start = { opacity: 0, transform: 'translateY(-90px)' };
    const end = { opacity: 1, transform: 'translateY(0)' };
    const start2 = { opacity: 0, transform: 'translateY(100px)' };
    const end2 = { opacity: 1, transform: 'translateY(0)' };

    const WhatsApp = () => {
        const phoneNumber = '+966551199935';
        const message = 'Hello, Abraaj  ';
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };

    return (
        <AboutWrapper id='about'>
            <img className='underline w-100' src={UnderLine} alt="img" />
            <Container>
                <Row>
                    <Col>
                        <Box className='about-content Box-shadow mb-0' style={{ backgroundImage: `url(${AboutBg})` }}>
                            <Row className='about-rows'>
                                <Col ref={ref} lg={6} md={12}>


                                    <Box className='about-img about-img-wrap-1'>
                                        <Animate
                                            play={inView}
                                            duration={1}
                                            start={start}
                                            end={end}
                                        >
                                            <img className='about-img-1 Box-shadow' src={About1} alt="" />
                                        </Animate>
                                    </Box>
                                    <Box className='about-content-wrapper'>
                                        <SubHeading className='sub-heading after' > {t('Who_Are_We')} </SubHeading>
                                        <MainHeading className=' main-heading themeColor' size="32px">{t('Premier_Source')} </MainHeading>
                                        <MainHeading className=' main-heading mb-3 themeColor' size="32px"> {t('for_Trained_Workers')} </MainHeading>
                                        <TextStyled color='var(--text)'>{t('about_content')}</TextStyled>
                                    </Box>

                                    <Box className='about-img-wrap-2'>
                                        <Animate
                                            play={inView}
                                            duration={1}
                                            start={start2}
                                            end={end2}
                                        >
                                            <img className='about-img about-img-2 Box-shadow' src={About2} alt="img" />
                                        </Animate>
                                    </Box>
                                </Col>
                            </Row>
                        </Box>
                    </Col>
                </Row>
            </Container>
            <Achievement />
        </AboutWrapper >
    )
}

export default About