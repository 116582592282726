import React from 'react'
import { WorkerWraper } from './styled'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, HeadingStyled, LazyImage, MainHeading } from '../../Styled/Elements'
import { ArrowRight, Location, PdfDwld, UnderLine, WorkerBg, WorkerImg, WorkerLogo } from '../../Styled/AllImages'
import { ContactButton, TextWithIcon, TextWithIconFile } from '../../Styled/CustomElements'
import { useTranslation } from 'react-i18next'

const Worker = () => {
    const { t } = useTranslation();
    const WhatsApp = () => {
        const phoneNumber = '+966551199935';
        const message = 'Hello, Abraaj  ';
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };
    const pdfUrl = 'path-to-your-pdf-file.pdf';

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = 'filename.pdf'; // Optional: set the download attribute if you want to force download with a specific filename
    link.target = '_blank'; // Open in a new tab
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
    return (
        <>
            <WorkerWraper className='bg-setting workers' style={{ backgroundImage: `url(${WorkerBg})` }}>
                <Container>
                    <Row className='common-space'>
                        <Col lg={6} md={12}>
                            <Box>
                                <HeadingStyled className='silverColor providing'> {t('providing')} </HeadingStyled>
                                <MainHeading className='main-heading themeColor trained-worker' transform="lowercase" size="35px"> {t('providing_workers')}</MainHeading>
                                <MainHeading className='silverColor mb-2 trained-worker' size="28px" transform="lowercase"> {t('fulfill')} </MainHeading>
                                <Box className='whpPf'>
                                    <ContactButton icon={ArrowRight} label={t('Get_Started')} onClick={WhatsApp} />
                                    {/* <TextWithIcon icon={PdfDwld} title={t('Download_PDF')} onClick={handleDownload} /> */}
                                    <TextWithIconFile icon={PdfDwld} title={t('Download_PDF')} />
                                    
                                </Box>
                            </Box>
                        </Col>
                        <Col lg={6} md={12}>
                            <Box className='workers-man'>
                                <LazyImage className='worker-img' src={WorkerImg} />
                            </Box>
                        </Col>

                    </Row>
                </Container>
                <LazyImage className='worker-logo' src={WorkerLogo} />
            </WorkerWraper>
            <img className='w-100' src={UnderLine} alt="img" />
        </>
    )
}

export default Worker