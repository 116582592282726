import styled from 'styled-components'


export const HeaderContainer = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 110;
    width: 100%;
    padding:14px 0;
    transition: all 0.5s ease-in-out;
    .nav-bar{
        display:flex;  
        align-items: center;
        justify-content: space-between;
    }
    .nav-bar-list{
        display:flex;  
        align-items: center;
        gap: 35px;
    }
    .nav-bar-list a{
        color:var(--white);
        padding-bottom: 12px;
        position: relative;
        font-size:16px;
        font-family: var(--semiBold);
        &:focus, &:hover, &.active{
            background: var(--themeColor);
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
            font-family: var(--semiBold);
            &::before, &.active::before{
                content: '';
                position: absolute;
                bottom: 0;
                right: 0;
                left: 0;
                margin: auto;
                width: 100%;
                height: 3px;
                background: var(--themeColor);
            }
            
        }
    }
    .logo{
        cursor:pointer;
        transition: all 0.5s ease-in-out;
        svg{
            width:80px;
            transition: all 0.5s ease-in-out;
        }
    }
    &.sticky{
        background: var(--darkColor);
        box-shadow: 0px 3px 10px #001b2ec7;
        z-index: 200;
        
        & .logo {
            width: 80px;
            height: 80px;
        }
        & .logo svg {
            // width: 100px;
            height: 80px;
        }
    }
    .toggleBtn{
        display: none;
    }
}
`