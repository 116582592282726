import styled from "styled-components";

export const AboutWrapper = styled.section`

    width: 100%;
    height: 100%;
    .underline{
        position: relative;
        top: -4px;
    }
    .about-content{
        position: relative;
        top: -50px;
        width:100%;
        background-color: var(--white);
        border-radius: 16px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: bottom;

    }
    .about-rows{
        padding-top:55px;
        padding-bottom:150px;
        position:relative;
        align-items: center;
        justify-content: center;
        // height: 500px;
    }
    .about-img{
        &>div{
            border-radius:16px;
        }
    }
    .about-img-wrap-1{
        border-radius:16px;
        position: absolute;
        left: -50px;
        top: 90px;

        & img{
            border-radius:16px;
        }
    }
    .about-img-wrap-2{
        border-radius:16px;
        position: absolute;
        right: -50px;
        bottom: 60px;
        & img{
            border-radius:16px;
        }
    }

`
export const AchievementWrapper = styled.div`
margin:55px 0;
background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
.achieve-box{
    border: 1px solid #C7AF76;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-radius: 12px;
    padding: 0 25px;
    margin-top:35px;
    height:180px;
}




`