import React from 'react'
import ContactForm from './ContactForm'
import { ContactWrapper } from './Styled'
import { Col, Container, Row } from 'react-bootstrap'
import { TextButton, TextWithIcon, TextWithIconFile } from '../../Styled/CustomElements'
import { Box, MainHeading, SubHeading, TextStyled, } from '../../Styled/Elements'
import { Contactlogo, Email, Location, PdfDwld, Phone, UnderLine } from '../../Styled/AllImages'
import { useTranslation } from 'react-i18next'

const Contact = () => {
  const WhatsApp = () => {
    const phoneNumber = '+966551199935';
    const message = 'Hello, Abraaj  ';
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, '_blank');
  };
  const Map = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3625.062699793756!2d46.758571010944486!3d24.69037127794914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f06cf7baf3ce9%3A0xddcab026a24890d3!2sAhmad%20Ibn%20Ghalib%2C%20Riyadh%20Saudi%20Arabia!5e0!3m2!1sen!2s!4v1714735326167!5m2!1sen!2s"
  const { t } = useTranslation();
  const email = ''

  return (
    <>
      <img className='w-100' src={UnderLine} alt='img' />
      <ContactWrapper id='contact' className='' style={{ background: `var(--darkColor)` }} >
        <Container>
          <Box className='containerContact'>
            <Row className='common-space'>
              <Box className='heading-content'>
                <SubHeading className='sub-heading before_after_white' color='var(--white)'>{t('Contact_Us')}</SubHeading>
                <MainHeading className='themeColor main-heading'> {t("Let_Talk")} </MainHeading>
                <TextStyled size='28px' family='var(--semiBold)' color="var(--white)"> {t('content_text')} </TextStyled>
              </Box>
            </Row>
            <Row>
              <Box className='contact-info'>
                <TextButton icon={Phone} label='+966551199935' onClick={WhatsApp} />
                <TextWithIcon icon={Email} title='info@abrajtowers.com' href="mailto:info@abrajtowers.com" />
                <TextWithIcon icon={Location} title='Riyadh 12834, Saudi Arabia' />
                <TextWithIconFile icon={PdfDwld} title={t('Download_PDF')} />
              </Box>
            </Row>
            <Row className='align-items-center'>
              <Col lg={6} md={12} className='contact-Us'>
                <Box className='contect-with-us'>
                  <ContactForm />
                </Box>
              </Col>
              <Col lg={6} md={12}>
                <Box className='contect-with-you'>
                  <Box className='contact-box'>
                    <iframe src={Map} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </Box>
                </Box>
              </Col>
            </Row>
          </Box>
        </Container>
        <img className='contact-bm-logo' src={Contactlogo} alt='img' />
      </ContactWrapper>
    </>
  )
}

export default Contact