import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, HeadingStyled, MainHeading, SpanStyled, SubHeading, TextStyled } from '../../Styled/Elements'
import { ProjectDigitalData, ProjectOtherData } from '../../data/Data'
import { ChooseWapper } from './styled'
import { useTranslation } from 'react-i18next'
import { ChooseBg } from '../../Styled/AllImages'
import Worker from './Worker'
import { useInView } from 'react-intersection-observer'
import { Animate, AnimateGroup } from 'react-simple-animate'

const Choose = () => {
    const { t, i18n } = useTranslation();
    const [ref, inView] = useInView({
        triggerOnce: true,
    });
    const isRTL = i18n.dir() === 'rtl';
    const start = isRTL
        ? { opacity: 0, transform: "translateX(15px)" }
        : { opacity: 0, transform: "translateX(-15px)" };
    const end = { opacity: 1, transform: "translateX(0)" };
    return (

        <ChooseWapper id='choose'>
            <Container>
                <Row>
                    <Box className='heading-content'>
                        <SubHeading className='sub-heading before_after' color='var(--darkColor)'>{t('Why_Choose_Us')} </SubHeading>
                        <MainHeading className='themeColor main-heading'> {t('Why_Choose_Us_content')} </MainHeading>
                    </Box>
                </Row>
            </Container>
            <Container className='comparing'>
                <Box ref={ref} className='Box-shadow'>
                    <Row >
                        <Col lg={6} md={12}>
                            <Box className='Choose-digital'>
                                <HeadingStyled className='choose-heading' size='28px' color='var(--darkColor)' weight='normal'> {t('Other_Companies')} </HeadingStyled>
                                {
                                    ProjectOtherData.map((list, key) => (
                                        <Box key={key} className='project-wapper'>
                                            <AnimateGroup play={inView} >
                                                <Animate

                                                    delay={list.delay}
                                                    sequenceIndex={list.sequenceIndex}
                                                    start={start}
                                                    end={end}
                                                >
                                                    <Box className='project-content compaire'>

                                                        {list.img}
                                                        <Box>
                                                            <HeadingStyled color='var(--themeColor)' size='18px' weight='normal'>{t(list.heading)}</HeadingStyled>
                                                            <TextStyled size="14px">{t(list.text)}</TextStyled>
                                                        </Box>
                                                    </Box>
                                                </Animate>
                                            </AnimateGroup>
                                        </Box>
                                    ))
                                }
                            </Box>
                        </Col>
                        <Col lg={6} md={12} >
                            <Box className='project-other'>
                                <img className='project-bg' src={ChooseBg} alt='img' />
                                <HeadingStyled className='choose-heading' size='28px' color='var(--darkColor)' weight='normal'> {t('Abraj_alamal_com')} </HeadingStyled>
                                {
                                    ProjectDigitalData.map((list, key) => (
                                        <Box key={key} className='project-wapper'>
                                            <AnimateGroup play={inView} >
                                                <Animate
                                                    delay={list.delay}
                                                    sequenceIndex={list.sequenceIndex}
                                                    start={start}
                                                    end={end}
                                                >
                                                    <Box className='project-content'>
                                                        {list.img}
                                                        <Box>
                                                            <HeadingStyled color='var(--themeColor)' size='18px' weight='normal'>{t(list.heading)}</HeadingStyled>
                                                            <TextStyled size="14px">{t(list.text)}</TextStyled>
                                                        </Box>
                                                    </Box>
                                                </Animate>
                                            </AnimateGroup>
                                        </Box>
                                    ))
                                }
                            </Box>
                        </Col>
                    </Row>
                </Box >
            </Container >
            <Worker />
        </ChooseWapper >
    )
}

export default Choose