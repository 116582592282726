import { Alimited, Aservice, Asolution, Atimer, Client1, Client10, Client11, Client12, Client13, Client14, Client15, Client16, Client17, Client18, Client19, Client2, Client20, Client21, Client22, Client23, Client24, Client25, Client26, Client27, Client28, Client29, Client3, Client30, Client31, Client32, Client33, Client4, Client5, Client6, Client7, Client8, Client9, Excellence, Experience, Expertise, Gallery1, Gallery2, Gallery3, Gallery4, Gallery5, Gallery6, Gallery7, Gallery8, Limited, Mainten1, Mainten2, Mainten3, Mainten4, Mainten5, Mainten6, Mainten7, Privacy, Qualified, Quality, Service, Solution, System, Timer } from "../Styled/AllImages";
import { LazyImage } from "../Styled/Elements";


export const NavDataConfig = [
    {
        to: 'home',
        label: 'Home',
    },
    {
        to: 'about',
        label: 'About',
    },
    {
        to: 'service',
        label: 'Services',
    },
    {
        to: 'choose',
        label: 'Why_Choose_Us',
    },
    {
        to: 'gallery',
        label: 'Portfolio',
    },
    {
        to: 'client',
        label: 'Client',
    },

    {
        to: 'contact',
        label: 'Contact_Us',
    },
]


export const maintenanceData = [
    {
        text: 'Building_Maintenance',
        img: Mainten1,
    },
    {
        text: 'Restaurant_Maintenance',
        img: Mainten2,
    },
    {
        text: 'Garden_Maintenance',
        img: Mainten3,
    },
    {
        text: 'Plumbing_Maintenance',
        img: Mainten4,
    },
    {
        text: 'Air_Conditioning_Maintenance',
        img: Mainten5,
    },
    {
        text: 'Electrical_Maintenance',
        img: Mainten6,
    },
    {
        text: 'Home_Appliance_Maintenance',
        img: Mainten7,
    },

]
export const oerationData = [
    {
        title: "Operations",
        dataList: [
            {
                text: 'Building_Maintenance',
                img: Mainten1,
            },
            {
                text: 'Restaurant_Maintenance',
                img: Mainten2,
            },
            {
                text: 'Garden_Maintenance',
                img: Mainten3,
            },
            {
                text: 'Garden_Maintenance',
                img: Mainten4,
            },
            {
                text: 'Garden_Maintenance',
                img: Mainten5,
            },
            {
                text: 'Garden_Maintenance',
                img: Mainten6,
            },
            {
                text: 'Garden_Maintenance',
                img: Mainten7,
            },
        ],
    },

]

export const ProjectDigitalData = [
    {
        img: <LazyImage src={Alimited} />,
        heading: "Proven_Expertise",
        text: "Proven_Expertise_content",
        delay: "1.3",
        sequenceIndex: '0',
    },
    {
        img: <LazyImage src={Aservice} />,
        heading: "Comprehensive_Services",
        text: "Comprehensive_Services_content",
        delay: "1.6",
        sequenceIndex: '1',
    },
    {
        img: <LazyImage src={Asolution} />,
        heading: "Personalized_Approach",
        text: "Personalized_Approach_content",
        delay: "1.9",
        sequenceIndex: '2',
    },
    {
        img: <LazyImage src={Atimer} />,
        heading: "Reliable_and_Timely",
        text: "Reliable_and_Timely_content",
        delay: "2.3",
        sequenceIndex: '3',
    },
]


export const ProjectOtherData = [
    {
        img: <LazyImage src={Limited} />,
        heading: "Limited_Experience",
        text: "Limited_Experience_content",
        delay: "0.4",
        sequenceIndex: '0',
    },
    {
        img: <LazyImage src={Service} />,
        heading: "Fragmented_Services",
        text: "Fragmented_Services_content",
        delay: "0.6",
        sequenceIndex: '1',
    },
    {
        img: <LazyImage src={Solution} />,
        heading: "Generic_Solutions",
        text: "Generic_Solutions_content",
        delay: "0.9",
        sequenceIndex: '2',
    },
    {
        img: <LazyImage src={Timer} />,
        heading: "Unreliable_Performance",
        text: "Unreliable_Performance_content",
        delay: "1.2",
        sequenceIndex: '3',
    },
]


export const photos = [
    {
        src: Gallery1,
    },
    {
        src: Gallery2,
    },
    {
        src: Gallery3,
    },
    {
        src: Gallery4,
    },
    {
        src: Gallery5,
    },
    {
        src: Gallery6,
    },
    {
        src: Gallery7,
    },
    {
        src: Gallery8,
    },



];


export const ClientData = [
    {
        logo: <Client1 />,
        delay: "0.3",
        sequenceIndex: '0',

    },
    {
        logo: <Client2 />,
        delay: "0.4",
        sequenceIndex: '1',
    },
    {
        logo: <Client3 />,
        delay: "0.5",
        sequenceIndex: '2',
    },
    {
        logo: <Client4 />,
        delay: "0.6",
        sequenceIndex: '3',
    },
    {
        logo: <Client5 />,
        delay: "0.7",
        sequenceIndex: '4',
    },
    {
        logo: <Client6 />,
        delay: "0.8",
        sequenceIndex: '5',
    },
    {
        logo: <Client7 />,
        delay: "0.9",
        sequenceIndex: '6',
    },
    {
        logo: <Client8 />,
        delay: "1.0",
        sequenceIndex: '6',
    },
    {
        logo: <Client9 />,
        delay: "1.1",
        sequenceIndex: '8',
    },
    {
        logo: <Client10 />,
        delay: "1.2",
        sequenceIndex: '9',
    },
    {
        logo: <Client11 />,
        delay: "1.3",
        sequenceIndex: '10',
    },
    {
        logo: <Client12 />,
        delay: "1.4",
        sequenceIndex: '11',
    },
    {
        logo: <Client13 />,
        delay: "1.5",
        sequenceIndex: '12',
    },
    {
        logo: <Client14 />,
        delay: "1.6",
        sequenceIndex: '13',
    },
    {
        logo: <Client15 />,
        delay: "1.7",
        sequenceIndex: '14',
    },
    {
        logo: <Client16 />,
        delay: "1.8",
        sequenceIndex: '15',
    },
    {
        logo: <Client17 />,
        delay: "1.9",
        sequenceIndex: '16',
    },
    {
        logo: <Client18 />,
        delay: "2.1",
        sequenceIndex: '17',
    },
    {
        logo: <Client19 />,
        delay: "2.2",
        sequenceIndex: '18',
    },
    {
        logo: <Client20 />,
        delay: "2.3",
        sequenceIndex: '19',
    },
    {
        logo: <Client21 />,
        delay: "2.4",
        sequenceIndex: '20',
    },
    {
        logo: <Client22 />,
        delay: "2.5",
        sequenceIndex: '21',
    },
    {
        logo: <Client23 />,
        delay: "2.6",
        sequenceIndex: '22',
    },
    {
        logo: <Client24 />,
        delay: "2.7",
        sequenceIndex: '23',
    },
    {
        logo: <Client25 />,
        delay: "2.8",
        sequenceIndex: '24',
    },
    {
        logo: <Client26 />,
        delay: "2.9",
        sequenceIndex: '25',
    },
    {
        logo: <Client27 />,
        delay: "3.1",
        sequenceIndex: '26',
    },
    {
        logo: <Client28 />,
        delay: "3.2",
        sequenceIndex: '27',
    },
    {
        logo: <Client29 />,
        delay: "3.3",
        sequenceIndex: '28',
    },
    {
        logo: <Client30 />,
        delay: "3.4",
        sequenceIndex: '29',
    },
    /* {
        logo: <Client31 />,
        delay: "3.5",
        sequenceIndex: '30',
    }, */
    {
        logo: <Client32 />,
        delay: "3.6",
        sequenceIndex: '31',
    },
    {
        logo: <Client33 />,
        delay: "3.7",
        sequenceIndex: '32',
    },


]


export const achieveData = [
    {
        heading: "2000",
        text: "Professional_worker",
        delay: "0.6",
        sequenceIndex: '0',
    },
    {
        heading: "60",
        text: "Professional_employees",
        delay: "0.8",
        sequenceIndex: '1',
    },
    {
        heading: "5",
        text: "Satisfied_clients",
        delay: "1.0",
        sequenceIndex: '2',
    },
]