import styled from "styled-components";

export const GalleryWrapper = styled.section`
p{
    margin-top:10px;
    width:75%;
}
.gallery-img-wraping{
    width:100%;
    height:100%;
    & span{
        width: 100%;
        height: 100%;
    }

    & img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 12px;
    }
    
}
.gallery-wrapper-content{
    row-gap:15px;
}
.gallery-wrapper-content > div:nth-child(odd) >div{
    height: 386px;
}
.gallery-wrapper-content > div:nth-child(even)>div {
    height: 300px;
}
.gallery-wrapper-content-2 > div:nth-child(odd)>div {
    height: 300px;
}
.gallery-wrapper-content-2 > div:nth-child(even)>div {
    height: 386px;
    position: relative;
    top: -85px;
}
`


export const PortfolioWrapper = styled.section``