import ErrorPage from "../components/Page404/ErrorPage";
import Index from "./Index";

export const privateRoute = [
    {
        path: "/",
        element: <Index />,
    },
    {
        path: "*",
        element: <ErrorPage />,
    },
]